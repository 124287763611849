import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export type DialogAction = {
    title: string
    default?: boolean
    payload?: {
        action: string
        docPath: string
    }
}

export interface DialogsState {
    status: 'none' | 'info' | 'warning' | 'error' | 'question' | 'delete'
    actions?: [DialogAction]
    message: string
    additionalMessage?: string
    title: string
    translate: boolean
}

const initialState: DialogsState = {
    status: 'none',
    message: '',
    title: '',
    translate: false
}

const infoDialogDefaultActions = [
    {
        title: 'btn_ok',
        default: true
    }
]

export const dialogsSlice = createSlice({
    name: 'dialogs',
    initialState,
    reducers: {
        questionDialog: (state, action) => {
            console.log('questionDialog', action.payload.actions)
            state.actions = action.payload.actions
            state.message = action.payload.message
            state.additionalMessage = action.payload.additionalMessage
            state.translate = !!action.payload.translate
            state.title = 'question'
            state.status = 'question'
        },

        errorDialog: (state, action) => {

            console.log('errorDialog', action.payload)

            state.actions = action.payload.actions || infoDialogDefaultActions
            state.message = action.payload.message
            state.translate = !!action.payload.translate
            state.additionalMessage = action.payload.additionalMessage
            state.title = 'error'
            state.status = 'error'
        },

        infoDialog: (state, action) => {

            console.log('infoDialog', action.payload)

            state.actions = action.payload.actions || infoDialogDefaultActions
            state.message = action.payload.message
            state.translate = !!action.payload.translate
            state.additionalMessage = action.payload.additionalMessage
            state.title = 'info'
            state.status = 'info'
        },

        closeDialog: (state) => {
            state.status = 'none'
        }

    }
});

export const { questionDialog, closeDialog, infoDialog, errorDialog } = dialogsSlice.actions;

export const selectDialogStatus = (state: RootState) => state.dialogs.status
export const selectDialogMessage = (state: RootState) => state.dialogs.message
export const selectDialogActions = (state: RootState) => state.dialogs.actions
export const selectDialogTitle = (state: RootState) => state.dialogs.title
export const selectDialogTranslate = (state: RootState) => state.dialogs.translate
export const selectDialogAdditionalMessage = (state: RootState) => state.dialogs.additionalMessage
export default dialogsSlice.reducer;
