import React from "react";
import { DocGridColumn, RegistryDescriptor } from "../../../../app/types";
import { store } from "../../../../redux/store";
import { get } from "../../../api";
import { RegistryFilterOperator } from "../../../../app/types/types";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import { ApplicationUnit } from "../../../regs";

const ArticleEdit = React.lazy(() => import('./ArticleEdit'));
const ArticlesFilterForm = React.lazy(() => import('./ArticlesFilterForm'));

class ArticlesRD extends RegistryDescriptor {

    name: ApplicationUnit = 'articles'
    docEndpoint = 'articles'
    selector = {
        endpoint: 'articles_selector',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        queryParams: {
            _orderBy: 'code'
        },
    };
    gridEndpoint = 'articles_grid';
    
    columns: DocGridColumn[] =  [
        {name: 'code'},
        {name: 'name'},
        {name: 'barcode', width: 120},
        {name: 'color', width: 60},
        {name: 'unit_code', width: 40},
        {name: 'group_code', width: 60},
        {name: 'price', type: 'decimal', align: 'right', width: 80},
        {name: 'price_with_vat', type: 'decimal', align: 'right', width: 80},
        {name: 'sales_account', width: 120},
        {name: 'purchases_account', width: 120},
        {name: 'wh_qty', type: 'decimal', align: 'right', width: 50},
        {name: 'wh_total', type: 'decimal', align: 'right', width: 60},
        {name: 'reserved_purchase', type: 'decimal', align: 'right', width: 50},
        {name: 'reserved_sales', type: 'decimal', align: 'right', width: 50},
        {name: 'available', type: 'decimal', align: 'right', width: 50},
        {name: 'wh_min_qty', type: 'decimal', align: 'right', width: 50},
        {name: 'purchase_need', type: 'decimal', align: 'right', width: 50},
        {name: 'purchase_price', type: 'decimal', align: 'right', width: 60},
        {name: 'purchase_currency_code', width: 50},
        {name: 'supplier_code', width: 50}
    ];

    defaultDocument = {
        status: 0, 
        collections: {
            purchaseprices: [], 
            salesprices: []
        }
    }

    childrenDescriptors = {
        'collections/purchaseprices': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: {
                supplierCode: '',
                purchasePrice: 0,
                currency_id: 0,
                markup: 0
            },
        },
        'collections/salesprices': {
            enumeratedField: 'pricelist_id',
            initialNrValue: 1,
            defaultValue: {},
            constantRows: true
        }
    }

    public isFilterable(): boolean { return true }

    public getFilterForm = (docPath: string): JSX.Element | null => <ArticlesFilterForm docPath={docPath} />

    regFilterTranslations: RegFilterTranslations = {
        group_id: { field: 'group_id', operator: '=', type: 'selected_id_int'},
        supplier_id: { field: 'supplier_id', operator: '=', type: 'selected_id_int'},
    }

    public translateFilter(filter: any) : RegistryFilterOperator[] {
        const ret : RegistryFilterOperator[] = [];
        if(filter.active) {
            // console.log('active filter', filter)
            const types = []
            for(const [key, value] of Object.entries(filter)) {
                if(key !== 'active' && value !== undefined) {
                    if(key.startsWith('article_type_ext_') && !!value)
                        types.push(key.replace('article_type_ext_', ''));
                    else
                        this.processFilter(key, value, ret);
                }
            }
            if(types.length > 0) {
                ret.push({
                    field: 'article_type_ext',
                    operator: 'in',
                    value: types.join(',')
                })
            }
        }
        // console.log('translated filter', ret)
        return ret
    }

    /**
     * Appends necessary changes after document is loaded
     * @param doc document loaded
     * @returns modified loaded document
     */
    public async afterLoad(doc: any) { 
        const ret = await super.afterLoad(doc);

        // "TypeError: Cannot read properties of undefined (reading 'forEach')\n    
        // at ArticlesRD.afterLoad (http://localhost:3000/static/js/bundle.js:9983:33)\n    
        // at async doLoadRecord (http://localhost:3000/static/js/bundle.js:18289:15)\n    at async http://localhost:3000/static/js/bundle.js:18306:135"


        // Build sales prices table
        // TODO maybe this should be done in backend

        // fetch pricelists from db
        const state = store.getState();
        const db = state.databases.currentDatabase!.uri;
        const pls = (await get(state, db + '/pricelists', {_orderBy: 'code', _fetch: 'all'})).data;

        console.log('loaded pls', pls);

        // combine saved prices data with pricelists from db
        const pls2: any[] = []
        pls.forEach((pl: any) => (pls2[pl.id] = {pricelist_id: pl.id}));
        if(ret?.collections?.salesprices?.length)
            ret.collections.salesprices.forEach((sp: any) => (pls2[sp.pricelist_id] = sp));
        ret.collections.salesprices = pls2.filter((sp: any) => sp !== undefined && !!sp.pricelist_id);

        return ret
    }

    public getDetailForm(docPath: string): JSX.Element | null {
        return <ArticleEdit docPath={docPath} />
    }

    public async beforeSave(doc: any) {
        const newsp = doc.collections.salesprices
            .filter((sp: any) => !!sp.price || sp.discount)
            .map((sp: any) => ({
                ...sp,
                price: sp.price || 0,
                discount: sp.discount || 0
            }))
        return await super.beforeSave({...doc, collections: {...doc.collections, salesprices: newsp}})
    }

    /**
     * Modify type_id to be rounded type_ext / 1000
     * for legacy compatibility purposes
     * @param doc 
     * @param value 
     * @returns 
     */
    public on_update_type_ext_id = async (doc: any, value: any) => {
        return {...doc, type_id: Math.round(value / 1000)};
    }

}

export const articles = new ArticlesRD()