import React, { Key } from "react";
import { DocGridColumn, KeyValueList, RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";
import { splitDocPath, updateDocFieldValue } from "../../../../redux/docs/utils";
import { getFromCurrentDB, getRecordFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";
import dayjs from "dayjs";
import { LOCAL_SETUP_KEYS } from "../../../../app/const";
import * as Yup from 'yup';
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { addDays, round2 } from "../../../../app/utils";
import * as validators from "../../../../app/validationUtils";
import { DocLink } from "../../../../redux/docLinks/docLinksSlice";
import { DocLinkHandlerData, RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import { SalesInvoicePrintFormDataDescriptor, getSalesInvoicePrintForm } from "./print";
import { ReportDataDescriptor } from "../../../../app/types/ApplicationUnitDescriptor";

const EditForm = React.lazy(() => import('./SalesInvoiceForm'))
const FilterForm = React.lazy(() => import('./SalesInvoicesFilterForm'))
const AggPanel = React.lazy(() => import('./SalesInvoicesAggPanel'))

interface RevItem {
    vat_percent: number
    revenue: number
    vat_total: number
    vat_coef: number
    vat_c: number
}

class RevsTemp {
    [index: string]: RevItem
}

export interface SalesInvoiceReportDataDescriptor extends ReportDataDescriptor {
    currency?: any,
    modifier?: any,
    buyer?: any,
    buyer_country?: any,
    payer?: any,
    bank_accounts?: any,
    buyer_group: any
}

class SalesInvoicesRD extends RegistryDescriptor {

    // properties specific to this registry
    name: ApplicationUnit = 'salesinvoices';

    defaultDocument = {
        collections: {
            salesinvoicerows: [],
            salesinvoicerevenues: []
        }
    }

    private default_row = {
        article_id: 0,
        unit_id: 0,
        "quantity": 1,
        "price": 0,
        "memo": "",
        "article_code": "",
        "article_name": "",
        vat_percent: 0,
        unit_code: "",
        "discount": 0,
        vat_id: 0,
        "weight": 0,
        "realisation_account_id": 0,
        "sum": 0,
        "vat_total": 0,
        "waybillId": 0,
        "additionalText1": "",
        "additionalText2": "",
        "additionalText3": "",
        "additionalQuantity1": 0,
        "additionalQuantity2": 0,
        "additionalQuantity3": 0,
        "isDepositPackage": 0,
        "additionalText4": "",
        "additionalText5": "",
        "additionalText6": "",
        "additionalQuantity4": 0,
        "additionalQuantity5": 0,
        "additionalQuantity6": 0,
        "lot": "",
        obj_id: 1,
        vat_coef: 1
    }

    gridEndpoint = 'grid_salesinvoices';
    docEndpoint = 'salesinvoices';
    selector = {
        endpoint: 'salesinvoices',
        idCol: 'id',
        captionCol: 'nr',
        textCol: 'date',
    };
    
    columns: DocGridColumn[] = [
        {name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30},
        {name: 'd_caption', label: 'nr'},
        {name: 'doc_date', type: 'date', width: 100},
        {name: 'due_date', type: 'date', width: 100},
        {name: 'buyer_name'},
        {name: 'payer_name'},
        {name: 'total', type: 'decimal', align: 'right', width: 80},
        {name: 'currency_code', width: 60},
        {name: 'currency_rate', width: 60},
        {name: 'waybills', width: 60},
        {name: 'to_pay', type: 'decimal', align: 'right', width: 80},
        {name: 'summaryrows', width: 300},
        {name: 'obj', label: 'object', width: 100},
        {name: 'sender_login', width: 80},
        {name: 'grid_memo', width: 100},
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ];

    childrenDescriptors = {
        'collections/salesinvoicerows': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: this.default_row,
        }
    }

    regFilterTranslations : RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=' },
        doc_date_until: { field: 'doc_date', operator: '<=' },
        total_from: { field: 'total', operator: '>=', type: 'decimal' },
        total_until: { field: 'total', operator: '<=', type: 'decimal' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        object_id: { field: 'obj_id', operator: '=', type: 'selected_id_int' },
        buyer_id: { field: 'buyer_id', operator: '=', type: 'selected_id_int' },
        payer_id: { field: 'payer_id', operator: '=', type: 'selected_id_int' },
        currency_id: { field: 'currency_id', operator: '=', type: 'selected_id_int' },
    }
    
    public getTitle(doc: any): string {
        return doc ? (!!doc.title ? doc.title + ' ' : '') + (!!doc.nr ? doc.nr : '') : ''; // + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT)
    }
    
    public getDetailForm = (docPath: string): JSX.Element | null => <EditForm docPath={docPath} />

    public async getReportFormModule(reportData : SalesInvoicePrintFormDataDescriptor) {
        return await getSalesInvoicePrintForm(reportData);
    }

    public getFilterForm = (docPath: string): JSX.Element | null => <FilterForm docPath={docPath} />
    public getGridFooterAggPanel = (agg: any): JSX.Element | null => <AggPanel agg={agg} />

    public isFilterable = (): boolean => true

    public async handleLinkClick(docPath: string, doc: any, link: DocLink): Promise<DocLinkHandlerData> {

        if(link.ref === '#custom_export_earve') {
            const dp = splitDocPath(docPath)
            return {
                type: 'download', 
                url: '/' + dp.dbName + '/export_e_invoice/' + doc.id,
                caption: ((doc.title ? doc.title : '') + ' ' + this.getTitle(doc) + '.xml').trim()   // TODO correct filename
            };
        }

        if(link.ref === '#custom_email_einvoice') {
    
            // const pf = this.get Print Form();
            // console.log('PF', pf);

            // const bb = pdf(pf);
            // console.log('BB', bb);
            // const blob = bb.toBlob();
            // console.log('blob', blob);
            // downloadBlob(blob, 'test.pdf');


            // generate pdf
            // create e-mail with linked e-invoice
            // upload pdf
            // navigate to e-mail
        }

        return super.handleLinkClick(docPath, doc, link)
    }

    public getSettingsSidebarItems() {
        return [
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_CURRENCY_VISIBLE,
                caption: 'currency selector',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_INTEREST_VISIBLE,
                caption: 'interest editor',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_UNIT_COLUMN_VISIBLE,
                caption: 'unit visible',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_UNIT_COLUMN_EDITABLE,
                caption: 'unit editable',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_DISCOUNT_COLUMN_VISIBLE,
                caption: 'discount visible',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_VAT_COLUMN_VISIBLE,
                caption: 'vat visible',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_VAT_COLUMN_EDITABLE,
                caption: 'vat editable',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_TOTAL_WO_VAT_COLUMN_VISIBLE,
                caption: 'total w/o vat visible',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_TOTAL_VAT_COLUMN_VISIBLE,
                caption: 'total vat visible',
                default: true,
            },
            {
                id: LOCAL_SETUP_KEYS.SALESINVOICES_TOTAL_WITH_VAT_COLUMN_VISIBLE,
                caption: 'total with vat visible',
                default: true,
            }
        ]
    }


    // TODO form specific methods
    public async loadReportData(params: any): Promise<SalesInvoiceReportDataDescriptor> {
        const state = store.getState();

        // got special endpoint to load default printform data for salesinvoice
        const ret = await getRecordFromCurrentDB(state, 'salesinvoice_printform/' + params.id);
        return {
            ...ret,
            payer: ret.payer || ret.buyer,
            doc:  this.cleanupChildren(await this.afterLoad(ret.doc)),
            report_title: (this.getTitle(ret.doc)).trim(),
        };

        // const db = state.databases.currentDatabase!.uri;
        // const buyer = await getRecordFromCurrentDB(state, 'companies/' + params.buyer_id);
        // // const buyer_country = await getRecordFromCurrentDB(state, 'countries/' + buyer.legal_address_country_id);
        // const payer = params.buyer_id === params.payer_id ?
        //     buyer :
        //     await getRecordFromCurrentDB(state, 'companies/' + params.payer_id);
        
        // // email recipient
        // const email_to = buyer.email || payer.email;
        // const report_title = ((params.title || '') + ' ' + this.getTitle(params)).trim();

        // const r3 = {
        //     locale: params.language || buyer.language, // TODO buyer language also?
        //     doc: this.cleanupChildren(params),
        //     currency: await getRecordFromCurrentDB(state, 'currencies/' + params.currency_id),
        //     modifier: await getRecordFromCurrentDB(state, 'users_grid/' + params.modifier_id),
        //     buyer: buyer,
        //     buyer_country: await getRecordFromCurrentDB(state, 'countries/' + buyer.legal_address_country_id),
        //     payer: payer,
        //     bank_accounts: (await getFromCurrentDB(state, 'printable_bank_accounts')).data,
        //     buyer_group: await getRecordFromCurrentDB(state, 'companygroups/' + buyer.group_id),
        //     email_to: email_to,
        //     report_title: report_title
        // };

        // console.log('r3', r3);

        // return r3;
    }

    /**
     * Calculates total sum of the document
     * @param doc 
     * @returns 
     */
    public calcTotal(doc: any): any {
        return {...doc, total:  round2(doc.sum + doc.rounding)}
    }

    /**
     * @param setup General setup state object
     * @returns Filtered setup key-value pairs applicable to this type of document
     */
    public getSetup(setup: KeyValueList): KeyValueList {
        // Override this method to apply setup to the document
        // console.log('salesinvoices.getSetup', setup)

        const r : KeyValueList = {};
        Object.keys(setup).forEach((key: string) => {
            if(key.startsWith('SALESINVOICE.'))
                r[key.toLowerCase()] = setup[key];
        });

        console.log('r', r);

        return {
            ...r,
            rounding: setup['FIRMA.ROUND'] || '0',
        }
    }

    public calcTotals(doc: any, bAddEmptyRow: boolean = true): any {

        const rows = doc.collections.salesinvoicerows
        // calculate revenues
        const revs: RevsTemp = {}
        rows.forEach((row: any) => {
            if(row.__modified || row.vat_id) {
                const idx = row.vat_percent
                const rev = revs[idx] || {vat_percent: row.vat_percent, revenue: 0, vat_total: 0, vat_coef: 1, vat_c: 0}
                revs[idx] = {
                    ...rev, 
                    revenue: rev.revenue + row.sum, 
                    vat_total: rev.vat_total + row.vat_total, 
                    vat_c: rev.vat_c + row.vat_total
                }
            }
        })

        const rdoc = {...doc, collections: {...doc.collections, salesinvoicerevenues: Object.values(revs)}}

        // update sum
        const sum = rows.reduce((sum: number, row: any) => sum + (!!row.total ? row.total : 0), 0)
        const sumWoVat = rows.reduce((sum: number, row: any) => sum + (!!row.sum ? row.sum : 0), 0)

        // calculate rounding
        let rounding = 0
        if(rdoc.__setup && Number.parseInt(rdoc.__setup.rounding)) {
            const iRound = Number.parseInt(rdoc.__setup.rounding)
            const di = Math.sign(sum)
            const posSum = Math.abs(sum)
            const roundedSum = Math.trunc(posSum / (iRound / 100) + 0.5) * (iRound / 100) * di
            rounding = roundedSum - sum
        }
        const r = this.calcTotal({...rdoc, sum: sum, rounding: rounding, sumWoVat: sumWoVat, vat_total: sum - sumWoVat})
        return r
    }

    // public just to be able to test it
    public calculateRowSum = (row: any): any => {
        console.log('calculateRowSum for ', row);
        const rowDiscount = isNaN(Number.parseFloat(row.discount)) ? 0 : Number.parseFloat(row.discount)
        let sum = row.price * row.quantity * (1 - rowDiscount / 100)
        if(!sum) sum = 0
        let vat_total = row.vat_percent ? row.vat_percent * sum / 100 : 0
        if(!vat_total) vat_total = 0
        console.log('calculateRowSum sum: ', sum, ' vat_total: ', vat_total);
        return {
            ...row,
            sum: sum,
            vat_total: vat_total,
            total: sum + vat_total
        }
    }

    public updateVatData = async (doc: any, row: any, rowIndex: number) => {
        if(!row.vat_id)
            return row;
        const vatData = await getFromCurrentDB(store.getState(), 'vats/' + row.vat_id)
        const vat = vatData.data[0]
        const ret = {
            ...row,
            vat_percent: vat.vat_percent,
        }
        return this.calculateRowSum(ret)
    }

    public updateUnitData = async (doc: any, row: any, rowIndex: number) => {
        if(!row.unit_id)
            return row;
        const unitData = await getFromCurrentDB(store.getState(), 'units/' + row.unit_id)
        const unit = unitData.data[0]

        return {
            ...row,
            unit_code: unit.code,
        }
    }

    public updateArticleData = async (doc: any, row: any, rowIndex: number) => {
        if(!row.article_id) 
            return row;

        const url = 'fetch_salesinvoice_rowdata/?article_id=' + row.article_id 
            + '&buyer_id=' + doc.buyer_id
            + '&currency_id=' + doc.currency_id
            + '&rate=' + doc.rate 
            + '&drate=' + doc.drate;

        const articleData = await getFromCurrentDB(store.getState(),  url)

        const article = articleData.data[0]

        // remove fields that are not needed
        delete article.ret
        delete article.state

        const ret = {
            ...row,
            ...article,
            quantity: !!row.quantity ? row.quantity : 1,
        }
        return this.calculateRowSum(ret)
    }

    public onUpdateRow = async (doc: any, row: any, collectionPath: string, rowIndex: number, field: string): Promise<any> => {
        row.__modified = true;
        if(field === 'price' || field === 'quantity' || field === 'discount')
            return this.calculateRowSum(row)

        if(field === 'article_id')
            return await this.updateArticleData(doc, row, rowIndex)
        if(field === 'vat_id')
            return await this.updateVatData(doc, row, rowIndex)
        if(field === 'unit_id')
            return await this.updateUnitData(doc, row, rowIndex)
        return row;
    }

    private async onUpdateCurrency(doc: any, new_currency_id: number): Promise<any> {
        const currencyData = await getFromCurrentDB(store.getState(), 'currencies/' + new_currency_id)
        const currency = currencyData.data[0]
        const r = updateDocFieldValue(doc, 'rate', currency.rate);
        return updateDocFieldValue(r, 'drate', currency.drate);
    }

    private async onUpdateBuyer(doc: any, new_buyer_id: number): Promise<any> {
        const state = store.getState()
        const buyerData = await getFromCurrentDB(state, 'companies/' + new_buyer_id)
        const buyer = buyerData.data[0]

        const bCurrencyChanged = buyer.currency_id && buyer.currency_id !== doc.currency_id

        const payment_period = Number.parseInt(buyer.payment_period) || 0;

        const upd = {
            due_period: payment_period,
            due_date: this.calc_due_date({date: doc.date, due_period: payment_period}),
            interest: buyer.interest,
            currency_id: bCurrencyChanged ? buyer.currency_id : doc.currency_id,
            payer_id:  !!doc.payer_id && doc.payer_id !== new_buyer_id ? doc.payer_id : new_buyer_id,
        }

        // changing flat data, so no need to use updateDocFieldValue for now
        const ret = {
            ...doc,
            ...upd
        }

        if(bCurrencyChanged)
            return this.onUpdateCurrency(ret, buyer.currency_id);
        else
            return ret;
    }
    
    public onUpdate = async (doc: any, field: string, val: any): Promise<any> => {
        // row is updated
        if(field.startsWith('collections/salesinvoicerows'))
            return this.calcTotals(doc)

        // currency_id is updated, get currency record from API and update rate and drate
        if(field === 'currency_id')
            return await this.onUpdateCurrency(doc, val)
        if(field === 'buyer_id')
            return await this.onUpdateBuyer(doc, val)

        if(field === 'due_period' || field === 'date')
            return updateDocFieldValue(doc, 'due_date', this.calc_due_date(doc));

        // nothing to do, just return the doc
        return doc
    }

    public onDeleteRows(doc: any, path: string): any { 
        return this.calcTotals(doc)
    }

    private checkRow(row: any) {
        if(row.__modified)
            return this.calculateRowSum({
                ...this.default_row, 
                ...row, 
            })
        else
            return row
    }

    private checkRows(doc: any): any {
        return doc.collections.salesinvoicerows.map((row: any) => this.checkRow(row))
    }

    private calc_title(doc: any) {
        let ret: string = '';
        let title_var: string;
        // console.log('calc_title', doc);
        title_var = 'salesinvoice.title'
            + (doc.payment_type ? '.' + doc.payment_type : '')
            + (doc.language ? '.' + doc.language : '');
        if(doc.__setup && doc.__setup[title_var]) 
            ret = doc.__setup[title_var];
        
        console.log('calc_title: ', title_var, ret);
        return ret;
    }

    public async beforeSave(doc: any) : Promise<any> {

        const r1 = this.cleanupChildren(doc);

        const r2 = {
            rate: 1,    // TODO make it better?
            drate: 1,   // TODO make it better?
            ...r1,
            collections: {
                ...doc.collections,
                salesinvoicerows: this.checkRows(r1)
            },
            cash: doc.payment_type === 'cash',
            card_payment: doc.payment_type === 'card',
            title: this.calc_title(doc),
        };

        const r3 = await this.validate(r2)
        const r4 = this.calcTotals(r3, false)
        return r4
    }

    public isLockable(): boolean {
        return true;
    }

    public isLocked(doc: any): boolean {
        return doc && !!doc.status;
    }

    private calc_due_date = (doc: any): string => addDays(doc.date, Number.parseInt(doc.due_period) || 0) //dayjs(doc.date).add(doc.due_period, 'day').format('YYYY-MM-DD')

    // number retrieval from API
    public async fetch_new_number(doc: any): Promise<any> {
        const ret = await getFromCurrentDB(store.getState(), 'fetch_salesinvoice_nr', {
            doc_date: doc.date,
            obj_id: doc.src_obj_id,
            doc_id: doc.id || 0,
        });

        // console.log('ret: ', ret);

        return ret.data[0];
    }

    public async afterCopy(doc: any) { 

        const new_date = dayjs().format('YYYY-MM-DD'); // default date for new doc - today

        return {
            ...doc,
            id: undefined,
            attachments_count: 0,
            date: new_date,
            status: 0,
            ...await this.fetch_new_number({date: new_date, src_obj_id: doc.src_obj_id}),
        };
    }

    public async afterLoad(doc: any) {

        const payment_type = doc.cash ? 'cash' : (doc.card ? 'card' : 'bank');

        doc = {
            ...this.defaultDocument, 
            ...doc, 
            payment_type: payment_type
        };

        // If no rows defined, define empty array
        // If rows defined, calculate totals for every row
        const rows = doc && doc.collections && doc.collections.salesinvoicerows ? 
            doc.collections.salesinvoicerows.map((row: any) => this.calculateRowSum(row))
            : [];

        // Calculate totals, due date, add one empty row
        return this.calcTotals({
            date: dayjs().format('YYYY-MM-DD'), // TODO default for new doc?
            ...doc, 
            due_date: this.calc_due_date(doc),
            collections: {...doc.collections, salesinvoicerows: rows}, //
        });
    }

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            nr:             validators.number_required_positive(),
            date:           validators.date_required(),
            due_period:     validators.due_period(),
            buyer_id:       validators.number_required_positive(),
            currency_id:    validators.number_required_positive(),
            rate:           validators.currency_rate(),
            drate:          validators.currency_rate(),
            collections: Yup.object().shape({
                salesinvoicerows: Yup.array().of(
                    Yup.object().shape({
                        article_id:     validators.number_required_positive(),
                        unit_id:        validators.number_required_positive(),
                        quantity:       validators.number_required(),
                        price:          validators.number_required(),
                        vat_id:         validators.number_required_positive(),
                    })
                )
            })
        });
    }

    protected doc_links_blacklist = [
        '#custom_upload_einvoice',
        '#custom_email_einvoice',
        '#custom_export_edixmldoc',
        '#custom_export_ublinvoice',
    ];

    protected exec_and_reload_actions : {[key: string]: string} = {
        '#custom_arve_newstl': 'salesinvoice_to_gin',
        '#custom_arve_newkrstl': 'salesinvoice_to_grn',
    }

}

export const salesinvoices = new SalesInvoicesRD();