import React from "react";
import { RegistryDescriptor } from "../../../../app/types";
import { SelectorListItem } from "../../../../redux/selectors/selectorsSlice";
import * as Yup from 'yup';
import { ApplicationUnit } from "../../../regs";
import { DocGridColumn, attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import Action from "../../../../ui/components/Action";
import { useAppDispatch } from "../../../../app/hooks";
import { emailDocument } from "../../../../redux/docs/docsSlice";

const EditForm = React.lazy(() => import('./EmailEdit'))

class EmailsRD extends RegistryDescriptor {
    gridEndpoint = 'emails_grid'
    docEndpoint = 'emails'
    selector = {
        endpoint: 'emails',
        idCol: 'id',
        captionCol: 'subject',
        textCol: 'addr_to',
        getOptionLabel: (option: SelectorListItem) => option.caption,
        queryParams: {_orderBy: 'code'},
    }
    name: ApplicationUnit = 'emails'
    
    columns : DocGridColumn[] =  [
        {name: 'sent', type: 'emailed_status_icon', label: 'emailed_status_icon', width: 30},
        {name: 'addr_to', width: 200},
        {name: 'subject', width: 200},
        {name: 'sent_date', width: 140, type: 'datetime'},
        {name: 'addr_from', width: 200},
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]
    defaultDocument = {status: 0}
    getDetailForm = (docPath: string) => <EditForm docPath={docPath}/>
    getTitle = (doc: any): string  => {
        if(!!doc && doc.subject)
            return doc.subject
        else
            return ''
    }

    public isReadOnly = (doc: any): boolean => doc && doc.sent;

    public getValidationSchema(): Yup.ObjectSchema<any> {
        return Yup.object().shape({
            subject: Yup.string().required('Required'),
            addr_to: Yup.string().email('must_be_valid_email').required(),
            addr_cc: Yup.string().email('must_be_valid_email'),
            addr_bcc: Yup.string().email('must_be_valid_email'),
            body: Yup.string().required('Required'),
        });
    }

    public getEditButtons(doc: any): Action[] {

        const dispatch = useAppDispatch();

        const ret = super.getEditButtons(doc);
        ret.push({
            icon: 'email',
            tooltip: 'btn_email',
            onClick: () => {
                // console.log(doc);
                dispatch(emailDocument(doc.__fullPath));
            },
            disabled: !(doc && !doc.sent),
        });
        return ret;
    }

    public async afterCopy(doc: any) { 
        return {
            ...(await super.afterCopy(doc)),
            sent: false,
            sent_date: undefined,
            send_result: undefined,
            sender_id: undefined,
        };
    }

}

export const emails = new EmailsRD()
