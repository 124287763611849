import React from "react";
import { DocGridColumn, KeyValueList, RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from "../../../regs";
import { updateDocFieldValue } from "../../../../redux/docs/utils";
import { useIntl } from "react-intl";
import { getFromCurrentDB } from "../../../api";
import { store } from "../../../../redux/store";
import { INTL_DATEFORMAT } from "../../../../app/const";
import * as Yup from 'yup';
import { addDays, anyToType, getDaysBetweenDates, round2 } from "../../../../app/utils";
import * as validators from "../../../../app/validationUtils";
import { attachmentsCountColumns, labelsColumns, modificationColumns } from "../../../../app/types/DocGridColumn";
import { deflatten_objs, flatten_objs } from "../../../utils";
import { RegFilterTranslations } from "../../../../app/types/RegistryDescriptor";
import { FileUploadError } from "../../../../redux/docLinks/docLinksSlice";

const EditForm = React.lazy(() => import('./PurchaseEdit'));
const AggPanel = React.lazy(() => import('./PurchaseInvoicesAggPanel'));
const FilterForm = React.lazy(() => import('./PurchaseInvoicesFilterForm'));

class PurchaseInvoicesRD extends RegistryDescriptor {

    // properties specific to this registry
    name: ApplicationUnit = 'purchaseinvoices'
    file_drop_enabled: boolean = true

    defaultDocument = {
        rate: 1,
        drate: 1,
        status: 0,
        wh_id: 0,
        due_period: 0,
        collections: {
            purchaseinvoice_rows: [],
            purchaseinvoice_confirmations: [],
            purchaseinvoice_row_objects: [],
        }
    }

    default_row = {
        quantity: 1,
        name: '',
        vat_return_percent: 0,
        vat_coef: 0,
        total_vat: 0
    }

    gridEndpoint = 'grid_purchaseinvoices'
    docEndpoint = 'purchaseinvoices'
    selector = {
        endpoint: 'purchaseinvoices',   //TODO special endpoint
        idCol: 'id',
        captionCol: 'nr',
        textCol: 'date',
        memoCol: 'type_id'
    }

    childrenDescriptors = {
        'collections/purchaseinvoice_rows': {
            enumeratedField: 'nr',
            initialNrValue: 1,
            defaultValue: this.default_row,
        }
    }

    getTitle = (doc: any) => {
        const intl = useIntl()
        return doc ? (!!doc.nr ? doc.nr : '') + ' : ' + intl.formatDate(doc.date, INTL_DATEFORMAT) : ''
    }

    getDetailForm = (docPath: string) => <EditForm docPath={docPath} />

    public getGridFooterAggPanel = (agg: any): JSX.Element | null => <AggPanel agg={agg} />

    regFilterTranslations: RegFilterTranslations = {
        doc_date_from: { field: 'doc_date', operator: '>=', type: 'date' },
        doc_date_until: { field: 'doc_date', operator: '<=', type: 'date' },
        total_from: { field: 'total', operator: '>=', type: 'decimal' },
        total_until: { field: 'total', operator: '<=', type: 'decimal' },
        supplier_id: { field: 'supplier_id', operator: '=', type: 'selected_id_int' },
        article_id: { field: 'article_id', operator: '=', type: 'selected_id_int' },
        object_id: { field: 'obj_id', operator: '=', type: 'selected_id_int' },
        currency_id: { field: 'currency_id', operator: '=', type: 'selected_id_int' },
    }

    public isFilterable(): boolean {
        return true
    }

    public getFilterForm(docPath: string): JSX.Element | null {
        return <FilterForm docPath={docPath} />
    }

    // public getSettingsSidebarItems() {
    //     return []
    // }

    // public async loadReportData(params: any): Promise<any> {
    //     return null
    // }

    columns: DocGridColumn[] = [
        { name: 'locked', type: 'locked_icon', label: 'locked_icon', width: 30 },
        { name: 'nr' },
        { name: 'doc_date', type: 'date', width: 100 },
        { name: 'gl_date', type: 'date', width: 100 },
        { name: 'supplier_code', type: 'string', label: 'supplier_code_company' },
        { name: 'supplier_name', type: 'string' },
        { name: 'total', type: 'decimal', align: 'right' },
        { name: 'currency_code', width: 20, label: ' ' },
        { name: 'currency_rate', width: 20 },
        { name: 'to_pay', type: 'decimal', align: 'right' },
        { name: 'due_date', type: 'date', width: 100, label: 'due_date_grid' },
        { name: 'waybills' },
        { name: 'summaryrows' },
        { name: 'summaryobj' },
        { name: 'payments' },
        { name: 'grid_memo' },
        ...modificationColumns,
        ...attachmentsCountColumns,
        ...labelsColumns,
    ]

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return Yup.object().shape({
            document_date: validators.date_required(),
            date: validators.date_required(),
            due_period: validators.due_period(),
            supplier_id: validators.integer_required_positive(),
            currency_id: validators.integer_required_positive(),
            rate: validators.currency_rate(),
            drate: validators.currency_rate(),
            correction: validators.number(),
            collections: Yup.object().shape({
                purchaseinvoice_rows: Yup.array().of(
                    Yup.object().shape({
                        article_id: validators.integer_required_positive(),
                        quantity: validators.number_required(),
                        account_id: validators.integer_required_positive(),
                        vat_id: validators.integer_required_positive(),
                        price: validators.number_required(),
                    })
                )
            })
        });
    }

    public isLockable(): boolean {
        return true;
    }

    public isLocked(doc: any): boolean {
        return doc && !!doc.status;
    }

    /** 
     * @brief update document_date when date is changed and document date is undefined
    */
    public on_update_date = async (doc: any, value: any) => ({
        ...doc,
        document_date: !!doc.document_date ? doc.document_date : value,
        due_date: addDays(!!doc.document_date ? doc.document_date : value, doc.due_period || 0)
    })

    /**
     * @brief update date when document_date is changed and date is undefined
     */
    public on_update_document_date = async (doc: any, value: any) => ({
        ...doc,
        date: !!doc.date ? doc.date : value,
        due_date: addDays(value, doc.due_period || 0)
    })

    /**
     * @brief update currency_id when supplier_id is changed
     * @param doc document
     * @param new_supplier_id changed supplier_id
     * @returns modified document
     */
    protected on_update_supplier_id = async (doc: any, new_supplier_id: number): Promise<any> => {
        const d = (await getFromCurrentDB(store.getState(), 'companies/' + new_supplier_id)).data[0]
        if (!!d.currency_id)
            return updateDocFieldValue(doc, 'currency_id', d.currency_id);
        else
            return doc
    }

    /**
     * @brief update due_date when due_period is changed
     * @param doc document
     * @param new_due_period modified due_period
     * @returns modified document
     */
    protected on_update_due_period = async (doc: any, new_due_period: number): Promise<any> => {
        const new_dd = addDays(doc.document_date, new_due_period)
        if (new_dd !== doc.due_date)
            return updateDocFieldValue(doc, 'due_date', new_dd)
        else
            return doc
    }

    /**
     * @brief update due_date when due_period is changed
     * @param doc document
     * @param new_due_period modified due_period
     * @returns modified document
     */
    protected on_update_due_date = async (doc: any, new_due_date: string): Promise<any> => {
        const new_due_period = getDaysBetweenDates(doc.document_date, new_due_date)
        if (new_due_period !== doc.due_period)
            return updateDocFieldValue(doc, 'due_period', new_due_period)
        else
            return doc
    }

    /**
     * @brief gets currency from API and update rate and drate when currency_id is changed 
     * @param doc document
     * @param new_currency_id modified currency_id
     * @returns modified document
     */
    protected on_update_currency_id = async (doc: any, new_currency_id: number): Promise<any> => {
        const currencyData = await getFromCurrentDB(store.getState(), 'currencies/' + new_currency_id)
        const currency = currencyData.data[0]
        const r = updateDocFieldValue(doc, 'rate', currency.rate);
        return updateDocFieldValue(r, 'drate', currency.drate);
    }

    /**
     * @param setup General setup state object
     * @returns Filtered setup key-value pairs applicable to this type of document
     */
    public getSetup(setup: KeyValueList): KeyValueList {
        return {
            default_payment_account_id: setup['OSTUD.SR.KONTO'] || '',
        }
    }

    protected on_update_paid_in_cash = async (doc: any, new_value: boolean): Promise<any> => {
        console.log('on_update_paid_in_cash', new_value, doc.payment_account_id)
        if (new_value && !doc.payment_account_id) {
            const nv = doc.__setup?.default_payment_account_id
            return updateDocFieldValue(doc, 'payment_account_id', nv == '' ? undefined : anyToType(nv, 'int'))
        } else
            return doc
    }

    protected on_update_correction = async (doc: any, value: any): Promise<any> => this.calc_doc_totals(doc)

    /**
     * @brief when article_id is changed, load article from API and update article name, unit_id, account_id, vat_id, vat_return_percent
     * @param doc document the row belongs to
     * @param row row object (see purchaseinvoice_rows)
     * @param rowIndex integer row index, starting from 0
     * @param new_article_id new article id, integer
     * @returns updated row
     */
    protected on_update_purchaseinvoice_rows_article_id = async (doc: any, row: any, rowIndex: number, new_article_id: number): Promise<any> => {
        // TODO optimize this - use SP
        const d = (await getFromCurrentDB(store.getState(), 'articles/' + new_article_id)).data[0]

        if ((d.vat_id !== row.vat_id) || !row.vat_percent) {
            row.vat_id = d.vat_id
            row = await this.on_update_purchaseinvoice_rows_vat_id(doc, row, rowIndex, d.vat_id)
        }

        return {
            ...row,
            name: d.name,
            unit_id: d.unit_id,
            account_id: d.purchase_account_id,
            vat_id: d.purchase_vat_id,
            vat_return_percent: row.vat_return_percent || 100,
        }
    }

    /**
     * @brief when vat_id is changed, load vat from API and update vat_coef, vat_percent, total_vat, vat_return
     * @param doc 
     * @param row 
     * @param rowIndex 
     * @param new_vat_id 
     * @returns 
     */
    protected on_update_purchaseinvoice_rows_vat_id = async (doc: any, row: any, rowIndex: number, new_vat_id: number): Promise<any> => {
        const vats = await this.get_cached_vats()
        const vat = vats.find(v => v.id === new_vat_id)
        const total_vat = this.calculate_total_vat({ ...row, vat_percent: vat ? vat.vat_percent : 0 })

        return this.calculate_row_totals({
            ...row,
            vat_coef: vat ? vat.vat_coef : 0,
            vat_percent: vat ? vat.vat_percent : 0,
            total_vat: total_vat,
            vat_return: row.vat_return_percent ? round2(total_vat * row.vat_return_percent / 100) : 0,
        })
    }

    /**
     * @brief when vat_return_percent is changed, update vat_return
     * @param doc 
     * @param row 
     * @param rowIndex 
     * @param new_vat_return_percent 
     * @returns 
     */
    protected on_update_purchaseinvoice_rows_total_vat = async (doc: any, row: any, rowIndex: number, new_total_vat: number): Promise<any> =>
    ({
        ...row,
        total_vat_modified: row.total_vat != this.calculate_total_vat(row)
    })

    protected on_update_purchaseinvoice_rows_quantity = async (doc: any, row: any, rowIndex: number, new_quantity: number): Promise<any> =>
        this.calculate_row_totals(row)

    protected on_update_purchaseinvoice_rows_price = async (doc: any, row: any, rowIndex: number, new_price: number): Promise<any> =>
        this.calculate_row_totals(row)

    protected calc_doc_totals = (doc: any) => {
        let total_without_vat: number = 0;
        let total_vat: number = 0;
        let vat_return: number = 0;

        if(doc?.collections?.purchaseinvoice_rows?.length)
            doc.collections.purchaseinvoice_rows.forEach((r2: any) => {
                total_without_vat += (isNaN(r2.total_without_vat) ? 0 : Number.parseFloat(r2.total_without_vat))
                total_vat += (isNaN(r2.total_vat) ? 0 : Number.parseFloat(r2.total_vat))
                vat_return += (isNaN(r2.vat_return) ? 0 : Number.parseFloat(r2.vat_return))
            });

        total_without_vat = round2(total_without_vat);
        total_vat = round2(total_vat);
        vat_return = round2(vat_return);

        const ret = {
            total_without_vat: total_without_vat
            , total_vat: total_vat
            , vat_return: vat_return
            , total_with_vat: round2(total_without_vat + total_vat + (Number.parseFloat(doc.correction) || 0))
        };

        return {
            ...doc,
            ...ret
        };
    }

    /**
     * @brief = (row.quantity * row.price * row.vat_percent / 100) rounded to 2 decimals
     * @param row invoice row
     * @returns vat total rounded to 2 decimals
     */
    private calculate_total_vat = (row: any) => round2(row.quantity * row.price * row.vat_percent / 100)

    protected calculate_row_totals = (row: any) => {
        // console.log('calculate_row_totals (#' + row.nr + '): ', 
        //     row.quantity, ' * ', row.price, 
        //     'vat%: ', row.vat_percent, 
        //     'vat ret %', row.vat_return_percent)

        const total_without_vat = row.quantity * row.price
        const total_vat = row.total_vat_modified ? row.total_vat : this.calculate_total_vat(row)
        const vat_return = row.vat_return_percent ? round2(total_vat * row.vat_return_percent / 100) : 0

        return {
            ...row,
            total_without_vat: total_without_vat,
            total_vat: total_vat,
            vat_return: vat_return
        }
    }

    /**
     * calculate totals on every row update
     * @param doc 
     * @param field 
     * @param val 
     * @returns 
     */
    protected on_update_purchaseinvoice_rows = async (doc: any, field: string, val: any): Promise<any> =>
        this.calc_doc_totals(doc)
    // TODO maybe dont need to recalculate every time?

    protected on_delete_purchaseinvoice_rows = (doc: any, path: string): any =>
        this.calc_doc_totals(doc)


    private cached_vats: any[] = []

    private async get_cached_vats() {
        // load cached vats
        if (!this.cached_vats.length) {
            // TODO cache them locally (in redux?)
            const vats = await getFromCurrentDB(store.getState(), 'vats')
            this.cached_vats = vats.data
            // console.log('loaded vats', this.cached_vats)
        }
        return this.cached_vats
    }

    public async beforeSave(doc: any): Promise<any> {
        const r1 = this.cleanupChildren(doc);
        const r2 = await this.validate(r1);
        const r3 = this.calc_doc_totals(r2);
        const r4 = flatten_objs(r3, 'purchaseinvoice_rows', 'purchaseinvoice_row_objects');
        return r4;
    }

    public async afterCopy(doc: any) {
        return {
            ...doc,
            id: undefined,
            status: 0,
            einvoice_transaction: '',
            attachments_count: 0,
            nr: ''
        }
    }

    /**
     * @brief set up flags and collections after document is loaded
     * @param doc document, purchaseinvoice
     * @returns modified document
     */
    public async afterLoad(doc: any) {
        // console.log('afterLoad', doc)
        // const today = formatDateDB(new Date())
        // ========================================================

        const d2 = deflatten_objs(doc, 'purchaseinvoice_rows', 'purchaseinvoice_row_objects');

        try {
            const vats = await this.get_cached_vats()

            if (d2?.collections?.purchaseinvoice_rows !== undefined && d2.collections.purchaseinvoice_rows.length !== undefined)
                d2.collections.purchaseinvoice_rows = d2.collections.purchaseinvoice_rows.map((row: any) => {
                    const v = vats.find(v => v.id === row.vat_id)
                    return this.calculate_row_totals({
                        ...row,
                        vat_percent: v?.vat_percent,
                        total_vat_modified: !!row.vat_id && row.total_vat != this.calculate_total_vat({
                            quantity: row.quantity,
                            price: row.price,
                            vat_percent: v?.vat_percent
                        }),
                    })
                })

        } catch (e) {
            console.warn('afterLoad', e)
        }

        return this.calc_doc_totals({
            ...this.defaultDocument,
            ...d2,
            __document_date_changed: doc.document_date !== doc.date
        })
    }

    protected doc_links_blacklist = [
        '#custom_ostuarve_marker',
        '#custom_ostuhindade_parandus',
        '#custom_ostuarve_newtasumine', //TODO implement and remove from blacklist

    ];
    // protected doc_links_whitelist: string[] | undefined = undefined;

    protected exec_and_reload_actions : {[key: string]: string} = {
        '#custom_ostuarve_newstl_kreedit': 'purchase_to_gin',
        '#custom_ostuarve_newstl': 'purchase_to_grn',
    }

    public get_drop_files_endpoint(file: File): string {
        const ext = (file.name.split('.').pop() || '').trim().toLowerCase();
        console.log('get_drop_files_endpoint', ext);
        switch (ext) {
            case 'pdf': 
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'txt':
                return 'upload_receipt';
            case 'xml': 
                return 'upload_einvoice';
            default:
                throw new FileUploadError('unsupported_file_type', ext);
        }
    }
    
}

export const purchaseinvoices = new PurchaseInvoicesRD()