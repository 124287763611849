import { createSlice, createAsyncThunk, PayloadAction, SerializedError } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { APIError, del, get, postFile } from '../../profit/api';
import { useSelector } from 'react-redux';
import { splitDocPath } from '../docs/utils';
import { getRegDescriptor } from '../../profit';
import { fixFilename, PATH_DELIMITER } from '../../app/utils';

// TODO add error message to state

export class FileUploadError extends Error {
    constructor(message: string, public additional?: any) {
        super(message);
    }
}

export interface DocLink {
    caption: string
    ref: string
    linktype: number
}

export interface DocLinks {
    [key: string]: DocLink[]
}

export type DocLinksListItemStatus = 'invalid' | 'loading' | 'idle' | 'error' | 'uploading' | 'uploaded'

export interface CurrentInlineAttachment {
    url: string
    caption: string
}

export interface DocLinksListItem {
    status: DocLinksListItemStatus,
    lastError?: SerializedError,
    // lastError?: string
    list: DocLinks,
    currentInlineAttachment?: CurrentInlineAttachment,
    currentUpload?: string,
    uploadResult?: any
}

export interface DocLinksList {
    [key: string]: DocLinksListItem
}

export interface DocLinksState {
    list: DocLinksList
}

const initialState: DocLinksState = {
    list: {}
}

export const loadDocLinks = createAsyncThunk(
    'docLinks/load',
    async (payload: string, thunkApi) => {
        const state = thunkApi.getState() as RootState;
        const dp = splitDocPath(payload);
        if(dp.id === 'new' || dp.id === '')
            return {};
        const rd = getRegDescriptor(dp.regName);
        const response = await get(state, rd.getCorrectDocPath(payload) + '/links');
        return rd.filterDocLinks(response.data);
    }
)

export const deleteAttachment = createAsyncThunk(
    'docLinks/deleteAttachment',
    async (payload: string, thunkApi) => {
        console.log('deleteAttachment', payload);
        const state = thunkApi.getState() as RootState;
        const ret = await del(state, payload);
        return ret.data;
    }
);

export const uploadAttachment = createAsyncThunk(
    'docLinks/uploadAttachment',
    async (payload: { docPath: string, file: File }, thunkApi) => {

        const dp = splitDocPath(payload.docPath)
        if (dp.id === 'new')
            throw new Error('msg_save_document_to_attach')        
        const url = payload.docPath + '/attachments/' 
            + encodeURIComponent(fixFilename(payload.file.name).replaceAll(' ', '+'));

        try {
            const state = thunkApi.getState() as RootState;
            const ret = await postFile(state, url, payload.file);
            return ret.data;

        } catch (error) {
            if (error instanceof Error)
                return thunkApi.rejectWithValue({
                    name: error.name,
                    message: error.message,
                    additional: error.name === 'APIError' ? (error as APIError).additional : undefined
                })
            else
                throw error;
        }

    }
)

export const uploadFile = createAsyncThunk(
    'docLinks/uploadFile',
    async (payload: { docPath: string, endpoint: string, file: File }, thunkApi) => {

        const url = payload.endpoint + '/'
            + encodeURIComponent(fixFilename(payload.file.name).replaceAll(' ', '+'));

        try {
            const state = thunkApi.getState() as RootState;
            const ret = await postFile(state, url, payload.file);
            return ret.data;

        } catch (error) {
            if (error instanceof Error)
                return thunkApi.rejectWithValue({
                    name: error.name,
                    message: error.message,
                    additional: error.name === 'APIError' ? (error as APIError).additional : undefined
                })
            else
                throw error;
        }
    }
)

export const docLinksSlice = createSlice({
    name: 'docLinks',
    initialState,
    reducers: {
        resetAll: (state, action) => {
            state.list = {}
        },
        reset: (state, action: PayloadAction<string>) => {
            // action.payload is docPath
            // console.log('resetting ', action.payload)
            delete state.list[action.payload]
        },
        setCurrentInlineAttachment: (state, action: PayloadAction<{ docPath: string, url: string, caption: string }>) => {
            state.list[action.payload.docPath].currentInlineAttachment = {
                url: action.payload.url,
                caption: action.payload.caption
            }
        },
        resetCurrentInlineAttachment: (state, action: PayloadAction<string>) => {
            state.list[action.payload].currentInlineAttachment = undefined
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadDocLinks.pending, (state, action) => {
                const docPath = action.meta.arg
                state.list[docPath] = { status: 'loading', list: {} }
            })
            .addCase(loadDocLinks.fulfilled, (state, action) => {
                const docPath = action.meta.arg
                state.list[docPath] = { status: 'idle', list: action.payload }
            })
            .addCase(loadDocLinks.rejected, (state, action) => {
                const docPath = action.meta.arg
                state.list[docPath] = { status: 'error', list: {} }
                state.list[docPath].lastError = action.error
            })

            .addCase(uploadAttachment.pending, (state, action) => {
                const docPath = action.meta.arg.docPath
                if (state.list[docPath])
                    state.list[docPath].status = 'uploading'
                else
                    state.list[docPath] = { status: 'uploading', list: {} }
            })
            .addCase(uploadAttachment.fulfilled, (state, action) => {
                // reset status to reload links
                const docPath = action.meta.arg.docPath
                state.list[docPath].status = 'invalid'
            })
            .addCase(uploadAttachment.rejected, (state, action) => {
                const docPath = action.meta.arg.docPath;
                // console.log('uploadAttachment.rejected', action);
                state.list[docPath].status = 'error';
                state.list[docPath].lastError = action.payload || action.error;
            })

            .addCase(deleteAttachment.pending, (state, action) => {
                console.log('deleteAttachment.pending', action.meta.arg);
                const a = action.meta.arg.split(PATH_DELIMITER);
                const docPath = a.slice(0, a.length - 2).join(PATH_DELIMITER);
                state.list[docPath].status = 'loading';
            })
            .addCase(deleteAttachment.fulfilled, (state, action) => {
                const a = action.meta.arg.split(PATH_DELIMITER);
                const docPath = a.slice(0, a.length - 2).join(PATH_DELIMITER);
                state.list[docPath].status = 'invalid';
            })
            .addCase(deleteAttachment.rejected, (state, action) => {
                const a = action.meta.arg.split(PATH_DELIMITER);
                const docPath = a.slice(0, a.length - 2).join(PATH_DELIMITER);
                state.list[docPath].status = 'error';
                state.list[docPath].lastError = action.payload || action.error;
            })

            .addCase(uploadFile.pending, (state, action) => {
                console.log('uploadFile.pending', action);
                // const dp = splitDocPath(action.meta.arg.docPath);
                const docPath = action.meta.arg.docPath; //dp.dbName + PATH_DELIMITER + dp.regName;
                const fn = action.meta.arg.file.name;
                if (state.list[docPath]) {
                    state.list[docPath].status = 'uploading';
                    state.list[docPath].currentUpload = fn;
                } else {
                    state.list[docPath] = { status: 'uploading', list: {}, currentUpload: fn }
                }
                console.log('uploadFile.pending', state.list[docPath]);
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                // reset status to reload links
                console.log('uploadFile.fulfilled', action);
                // const dp = splitDocPath(action.meta.arg.docPath);
                const docPath = action.meta.arg.docPath; //dp.dbName + PATH_DELIMITER + dp.regName;
                state.list[docPath].status = 'uploaded';
                state.list[docPath].currentUpload = undefined;
                state.list[docPath].uploadResult = action.payload;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                console.log('uploadFile.fulfilled', action);
                // const dp = splitDocPath(action.meta.arg.docPath);
                const docPath = action.meta.arg.docPath; //dp.dbName + PATH_DELIMITER + dp.regName;
                state.list[docPath].status = 'error';
                state.list[docPath].lastError = action.payload || action.error;
                state.list[docPath].currentUpload = undefined;
                state.list[docPath].uploadResult = undefined;
            })
        }
});

export const { resetAll, reset, setCurrentInlineAttachment, resetCurrentInlineAttachment } = docLinksSlice.actions;

export const selectDocLinks = (docPath: string) =>
    useSelector((state: RootState) => state.docLinks.list[docPath]);

export const selectDocLinksStatus = (docPath: string): DocLinksListItemStatus =>
    useSelector((state: RootState) => state.docLinks.list[docPath]?.status || 'invalid');

export const selectDocLinksUploadResult = (docPath: string) =>
    useSelector((state: RootState) => state.docLinks.list[docPath]?.uploadResult);

export default docLinksSlice.reducer;
