import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from '../store'
import pack from '../../../package.json'

export type VersionStatusType = 'invalid' | 'loading' | 'idle' | 'error';

// TODO async thunk for version check

interface VersionState {
    currentVersion: string | undefined;
    hasNewVersion: boolean;
    status: VersionStatusType;
}

const initialState : VersionState = {
    currentVersion: undefined,
    hasNewVersion: false,
    status: 'invalid',
};

export const versionCheckerSlice = createSlice({
    name: "versionChecker",
    initialState,
    reducers: {
        setCurrentVersion: (state, action: PayloadAction<string | undefined>) => {
            state.currentVersion = action.payload;
            state.hasNewVersion = !!(state.currentVersion && state.currentVersion !== pack.version);
            state.status = 'idle';
        },
        setVersionStatus: (state, action: PayloadAction<VersionStatusType>) => {
            state.status = action.payload;
        }
    },
});

export const { setCurrentVersion, setVersionStatus } = versionCheckerSlice.actions;

export const selectCurrentVersion = (state: RootState) => state.versionChecker.currentVersion;
export const selectHasNewVersion = (state: RootState) => !!state.versionChecker.currentVersion && state.versionChecker.currentVersion !== pack.version;

export default versionCheckerSlice.reducer;