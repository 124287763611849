import { useEffect } from "react";
import { useAppDispatch } from "../../app/hooks";
import { setCurrentVersion, setVersionStatus } from "./versionCheckerSlice";

// TODO move this to general setup?
const VERSION_CHECK_INTERVAL = 300 * 1000; // Check every 5 minutes

export const useVersionChecker = () => {

    const dispatch = useAppDispatch();
    useEffect(() => {

        const checkVersion = async () => {
            try {
                // TODO move to async thunk
                setVersionStatus('loading');
                const response = await fetch('/version.json', { cache: 'no-cache' });
                const data = await response.json();
                const latestVersion = data.version;
                dispatch(setCurrentVersion(latestVersion));
            } catch (error) {
                console.error('Error checking version:', error);
                setVersionStatus('error');
            }
        };

        checkVersion();
        const interval = setInterval(checkVersion, VERSION_CHECK_INTERVAL);

        return () => {
            console.log('clearing interval');
            clearInterval(interval);
        }
    }, []);
}