import React from "react";
import { useAppDispatch } from "../hooks";
import { copyRecord, executeAndReload, saveRecord, undoRecord } from "../../redux/docs/docsSlice";
import { buildDocPath, splitDocPath, updateDocFieldValue } from "../../redux/docs/utils";
import { useNavigate } from "react-router-dom";
import { extractDocChild, getDocsLink } from "../utils";
import { SelectorDescriptor } from "./SelectorDescriptor";
import { DocGridColumn } from "./DocGridColumn";
import { infoDialog, questionDialog } from "../../redux/dialogs/dialogsSlice";
import * as Yup from 'yup';
import { DocLink, DocLinks, reset as resetDocLinks } from "../../redux/docLinks/docLinksSlice";
import { KeyValueList } from ".";
import { ApplicationUnitDescriptor, ApplicationUnitType } from "./ApplicationUnitDescriptor";
import { ExtValueType, FilterOperator, RegistryFilterOperator } from "./types";
import { ApplicationUnit } from "../../profit/regs";
import { ItemPrivileges } from "../../redux/privileges/privilegesSlice";
import Action from "../../ui/components/Action";
import { AsyncThunkAction } from "@reduxjs/toolkit";
import { translate_link_ref } from "../../profit/utils";

export interface DocLinkHandlerData {
    type: 'download' | 'dispatch' | 'navigate' | 'preview',
    url?: string,
    caption?: string,
    action?: { payload: any, type: string } | AsyncThunkAction<any, any, any>
    //typeof store.dispatch 
}

interface ChildrenDescriptor {
    enumeratedField: string
    initialNrValue?: number
    defaultValue: any
    constantRows?: boolean
}

type ChildrenDescriptors = {
    [path: string]: ChildrenDescriptor
}

type RegFilterTranslation = {
    field: FilterOperator
    operator: string
    type?: ExtValueType
}

export type RegFilterTranslations = {
    [key: string]: RegFilterTranslation
}

export class RegistryDescriptor extends ApplicationUnitDescriptor {

    name: ApplicationUnit = undefined
    type = 'registry' as ApplicationUnitType
    gridEndpoint: string = ''
    docEndpoint: string = ''
    regFilterTranslations: RegFilterTranslations = {}

    defaultDocument: any = {}

    selector: SelectorDescriptor = {
        endpoint: '',
        idCol: '',
        captionCol: '',
        textCol: '',
        memoCol: ''
    }

    childrenDescriptors: ChildrenDescriptors = {}

    columns: DocGridColumn[] = []

    // TODO not efficient
    public getCorrectDocPath(docPath: string): string {
        const dp = splitDocPath(docPath)
        return buildDocPath(dp.dbName, this.docEndpoint, dp.id)
    }

    public isNew(doc: any): boolean {
        return !doc || doc.id === 'new' || !doc.id;
    }

    public buildDocTitleMessage(doc: any): string {
        const isNew = this.isNew(doc);
        // const isLocked = this.isLockable() && this.isLocked(doc)
        return (this.isReadOnly(doc) ? 'view_' : isNew ? 'new_' : 'edit_') + this.name + '_title';
    }

    public getTitle(doc: any): string {
        if (doc && this.selector) {
            if (this.selector.textCol && doc[this.selector.textCol])
                return doc[this.selector.textCol]
            if (this.selector.captionCol && doc[this.selector.captionCol])
                return doc[this.selector.captionCol]
        }
        return ''
    }

    public getDetailForm(docPath: string): JSX.Element | null {
        return null
    }

    public getGridFooterAggPanel(agg: any): JSX.Element | null {
        return null
    }

    public enumerateCollection(path: string, collection: any[]): any[] {

        const enumerator = this.childrenDescriptors[path]
        if (enumerator) {
            const initialValue = enumerator.initialNrValue || 1
            const field = enumerator.enumeratedField
            // console.log('enumerating collection', path, collection)
            if (collection) {
                const nc = collection.map((item: any, index: number) => {
                    return { ...item, [field]: initialValue + index }
                })
                // console.log('enumerated collection', nc)
                return nc
            }

        }
        return collection
    }

    protected doc_links_blacklist: string[] | undefined = undefined;
    protected doc_links_whitelist: string[] | undefined = undefined;

    private filterDocLinksGroup(group: DocLink[]): DocLink[] {
        return group.filter((l: DocLink) => (
            (this.doc_links_blacklist == undefined || !(this.doc_links_blacklist.includes(l.ref))) // blacklist
            && (this.doc_links_whitelist == undefined || this.doc_links_whitelist.includes(l.ref)) // whitelist
        ))
    }

    /**
     * Modify or filter document links
     * @param list document links
     * @returns filtered document links
     */
    public filterDocLinks(list: DocLinks): DocLinks {

        const ret: DocLinks = {};
        for (const [key, value] of Object.entries(list)) {
            ret[key] = this.filterDocLinksGroup(value)
        }
        return ret


        // return {
        //     ...list,
        //     Dokumendid: list.Dokumendid.filter((l: DocLink) => (
        //         (this.doc_links_blacklist == undefined || !(this.doc_links_blacklist.includes(l.ref))) // blacklist
        //         && (this.doc_links_whitelist == undefined || this.doc_links_whitelist.includes(l.ref)) // whitelist
        //     ))
        // }
    }

    protected exec_and_reload_actions: { [key: string]: string } = {}

    public async handleLinkClick(docPath: string, doc: any, link: DocLink): Promise<DocLinkHandlerData> {

        if (!!this.exec_and_reload_actions[link.ref])
            return {
                type: 'dispatch',
                action: executeAndReload({ docPath, execute: this.exec_and_reload_actions[link.ref], setup: {} })
            }

        if (link.linktype === 2)    // 2 - file - downloadbale
            if (link.ref.startsWith('#inline_'))
                return {
                    type: 'preview',
                    url: docPath + '/attachments/' + link.caption.replaceAll(' ', '+'),
                    caption: link.caption
                };
            else
                return {
                    type: 'download',
                    url: docPath + '/attachments/' + link.caption.replaceAll(' ', '+'),
                    caption: link.caption
                };

        // Try to found global translations
        if (link.linktype === 1) {
            const translated_link = translate_link_ref(link.ref);
            if (translated_link !== undefined)
                return {
                    type: 'navigate',
                    url: translated_link
                };
        }

        // Default response - show dialog with link info and not implemented message
        // console.log('clicked', docPath, link)
        return {
            type: 'dispatch',
            action: infoDialog({
                message: link.caption + ' -- ' + link.ref,
                additionalMessage: 'not_implemeted_msg',
            })
        }
    }

    private valueIsNullForType(value: any, type?: ExtValueType): boolean {
        if (!type)
            return false;
        if (value === undefined || value === null)
            return true;
        if (type === 'selected_id_int')
            return value <= 0;
        if (type === 'int')
            return value === 0;
        if (type === 'decimal')
            return value == 0.0;
        return false;
    }

    protected processFilter(key: string, value: any, ret: RegistryFilterOperator[]) {
        if (key !== 'active' && value !== undefined) {
            const translation = this.regFilterTranslations[key];
            if (translation && !this.valueIsNullForType(value, translation.type))
                ret.push({
                    field: translation.field,
                    operator: translation.operator,
                    value: value as string
                });
        }
    }

    // Override in successors?
    public translateFilter(filter: any): RegistryFilterOperator[] {
        const ret: RegistryFilterOperator[] = [];
        if (filter && filter.active)
            for (const [key, value] of Object.entries(filter))
                this.processFilter(key, value, ret);
        return ret;
    }

    public isFilterable(): boolean {
        return false
    }

    // To override in extending classes
    public getFilterForm(docPath: string): JSX.Element | null {
        return null
    }

    public getFilterFormPath(dbName: string): string {
        const fv = this.getFilterVariable()
        if (fv === undefined)
            return ''
        else
            return dbName + '/localSetup/' + fv   //userSetupVarLocallySaved
    }

    public getFilterVariable(): string | undefined {
        if (this.name === undefined || !this.isFilterable())
            return undefined
        else
            return (this.name + '_filter').toUpperCase()
    }

    // To override in extending classes
    public isPrintable(): boolean {
        return true;
    }

    public isLockable(): boolean {
        return false
    }

    public isLocked(doc: any): boolean {
        return false
    }

    public isReadOnly(doc: any): boolean {
        return this.isLocked(doc)
    }

    public async check_row_updated(doc: any, field: string, val: any): Promise<any> {
        const paths = Object.keys(this.childrenDescriptors);
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i]
            if (field.startsWith(path)) {
                const a = field.split('/');
                const rowField = a.pop()!;
                const rowIndex = Number.parseInt(a.pop()!);
                const collectionPath = a.join('/');
                const row = extractDocChild(doc, collectionPath)[rowIndex];

                doc = await this.update_doc_on_row_field_change(doc, row, path, rowIndex, rowField, collectionPath);
                // const newRow = await this.onUpdateRow(doc, row, path, rowIndex, rowField);
                // doc = updateDocFieldValue(doc, collectionPath + '/' + rowIndex, newRow);
                // doc = this.appendChildrenEmptyRowsForCollection(doc, collectionPath, false);
                // // console.log('doc is updated', doc)
            }
        }
        return doc;
    }

    public async update_doc_on_row_field_change(doc: any, row: any, path: string, rowIndex: number, rowField: string, collectionPath: string) {
        const newRow = await this.onUpdateRow(doc, row, path, rowIndex, rowField);
        const ret1 = updateDocFieldValue(doc, collectionPath + '/' + rowIndex, newRow);
        const ret2 = this.appendChildrenEmptyRowsForCollection(ret1, collectionPath, false);
        return ret2;
    }

    public onUpdateRow = async (doc: any, row: any, collectionPath: string, rowIndex: number, field: string): Promise<any> => {
        const significantPathFragment = collectionPath.split('/')[1]
        // console.log('collection row #' + rowIndex + ' is updated:', field + ' = ', row[field])
        row.__modified = true
        const handlerFunctionName: any = 'on_update_' + significantPathFragment + '_' + field.replace(/\//g, '_')
        // console.log('looking for handler: ', handlerFunctionName, Object.keys(this))
        if (Object.keys(this).includes(handlerFunctionName))
            if (typeof (this as any)[handlerFunctionName] === 'function') {
                // console.log('calling handler function', handlerFunctionName)
                const modifications = await (this as any)[handlerFunctionName](doc, row, rowIndex, row[field])
                return {
                    ...row,
                    ...modifications
                }
            }
        return row
    }

    private getCollectionHandlerFunction = (field: string, prefix: string) => {
        if (field.startsWith('collections/') && this.childrenDescriptors !== undefined) {
            const keys = Object.keys(this.childrenDescriptors)
            for (let i = 0; i < keys.length; i++) {
                if (field.startsWith(keys[i])) {
                    const handlerFunctionName: string = prefix + keys[i].replace('collections/', '').replace(/\//g, '_')
                    if (Object.keys(this).includes(handlerFunctionName))
                        if (typeof (this as any)[handlerFunctionName] === 'function')
                            return (this as any)[handlerFunctionName]
                }
            }
        }
        return undefined
    }

    private handleCollectionChange = async (doc: any, field: string, val: any) => {

        const func = this.getCollectionHandlerFunction(field, 'on_update_')
        if (func) {
            const mods = await func(doc, field, val)
            return {
                ...doc,
                ...mods
            }
        } else
            return doc
    }

    public async onUpdate(doc: any, field: string, val: any): Promise<any> {
        const handlerFunctionName: any = 'on_update_' + field.replace(/\//g, '_')
        if (Object.keys(this).includes(handlerFunctionName))
            if (typeof (this as any)[handlerFunctionName] === 'function') {
                const ret = await (this as any)[handlerFunctionName](doc, val)
                return await this.handleCollectionChange(ret, field, val)
            }
        return await this.handleCollectionChange(doc, field, val)
    }
    public onDeleteRows(doc: any, path: string): any {
        const func = this.getCollectionHandlerFunction(path, 'on_delete_')
        if (func)
            return func(doc, path)
        else
            return doc
    }

    public getValidationSchema(): Yup.ObjectSchema<any> | undefined {
        return undefined
    }

    /**
     * 
     * @param doc document
     * @param path collection path
     * @returns new collection item
     */
    // TODO maybe do it async?
    public create_new_collection_item(doc: any, path: string): any {
        const descriptor = this.childrenDescriptors[path];
        if (descriptor)
            return descriptor.defaultValue;
        else
            return {};
    }

    public appendChildrenEmptyRowsForCollection(doc: any, path: string, force: boolean) {
        if (this.childrenDescriptors[path] && this.childrenDescriptors[path].constantRows)
            return doc;
        if (doc.collections === undefined)    // TODO hack, make it better
            doc = { ...doc, collections: {} };
        const collection = extractDocChild(doc, path) || []
        if (force || (collection
            && (
                (collection.length === 0)
                || (collection.length && collection[collection.length - 1]?.__modified)
            ))
        ) {
            if (force && collection.length > 0 && collection[collection.length - 1]?.__appended)
                return doc;
            collection.push({ ...this.create_new_collection_item(doc, path), __appended: true });
            const ret = updateDocFieldValue(doc, path, this.enumerateCollection(path, collection));
            return ret;
        } else
            return doc;
    }

    public appendChildrenEmptyRows(doc: any, force: boolean) {
        const m = doc.__dirty
        let ret = { ...doc }
        const keys =
            Object.keys(this.childrenDescriptors).forEach(path => {
                try {
                    ret = this.appendChildrenEmptyRowsForCollection(ret, path, force)
                } catch (e) {
                    console.warn('error appending empty rows', e, doc)
                }
            })
        ret.__dirty = m
        return ret
    }

    public cleanupChildren(doc: any): any {
        // console.log('cleanup children', doc, this.childrenDescriptors)
        let cleaned = { ...doc }
        Object.keys(this.childrenDescriptors).forEach(path => {
            const collection = [...extractDocChild(doc, path)]
            // console.log('cleanup children collection', path, collection)
            if (collection
                && collection.length
                && collection[collection.length - 1]?.__appended
                && !collection[collection.length - 1]?.__modified
            ) {
                // console.log('removing last item from collection', path)
                collection.pop()
                cleaned = updateDocFieldValue(cleaned, path, collection)
            }
        })
        return cleaned
    }

    /**
     * @param setup General setup state object
     * @returns Filtered setup key-value pairs applicable to this type of document
     */
    public getSetup(setup: KeyValueList): KeyValueList {
        // Override this method to apply setup to the document
        return {}
    }

    public async validate(doc: any): Promise<any> {
        const ret = this.cleanupChildren(doc)
        const schema = this.getValidationSchema()
        if (!!schema)
            return await schema.validate(ret, { abortEarly: false })
        else
            return ret
    }

    public async validateField(doc: any, field: string): Promise<any> {
        const schema = this.getValidationSchema()
        if (!!schema)
            return await schema.validateAt(field, doc, { abortEarly: false })
        else
            return doc[field]

    }

    public async beforeSave(doc: any) {
        return await this.validate(doc)
    }

    public forceAppendChildrenEmptyRows(doc: any) {
        if (!this.isReadOnly(doc))
            return this.appendChildrenEmptyRows(doc, true)
        else
            return doc
    }

    public async afterCopy(doc: any) {
        const ret = {
            ...doc,
            attachments_count: 0,
        };
        delete ret.id;
        return ret;
    }

    public async afterLoad(doc: any) {
        if (this.isNew(doc)) {
            const ret = { ...('object' === typeof this.defaultDocument ? this.defaultDocument : {}), ...doc };

            // if it is doc added from combo, then combo could send __addNew_code and __addNew_name 
            // to be used as code and name of new document
            if (doc.__setup && !!doc.__setup.__addNew_code && !!this.selector.captionCol)
                ret[this.selector.captionCol] = doc.__setup.__addNew_code;
            if (doc.__setup && !!doc.__setup.__addNew_name && !!this.selector.textCol)
                ret[this.selector.textCol] = doc.__setup.__addNew_name;

            return ret;

        } else
            return doc;

    }

    public getEditButtons(doc: any, privileges?: ItemPrivileges): Action[] {

        const navigate = useNavigate()
        const dispatch = useAppDispatch()

        const isNotNew = !!doc && !!doc.id && doc.id !== 'new';
        const canDelete = !!(isNotNew && !this.isReadOnly(doc) && (!privileges || privileges.d));
        const canAdd = !!(!privileges || privileges.c);
        const canSave = !this.isReadOnly(doc) && ((isNotNew && (!privileges || privileges.u)) || (!isNotNew && canAdd));

        const handleDelete = () => {

            console.log('handleDelete privileges', privileges);

            if (!canDelete)
                return dispatch(infoDialog({
                    message: 'msg_no_delete_privilege',
                    translate: true
                }))

            dispatch(questionDialog({
                message: 'msg_delete_document',
                translate: true,
                actions: [
                    {
                        title: 'btn_yes',
                        payload: {
                            action: 'deleteRecord',
                            docPath: doc.__fullPath
                        } //TODO hack to avoid nonseriaizable payload for async thunk
                    },
                    {
                        title: 'btn_no',
                        default: true
                    }
                ]
            }))
        }

        const handleAdd = () => {
            if (!canAdd)
                return dispatch(infoDialog({
                    message: 'msg_no_create_privilege',
                    translate: true
                }));
            return navigate(getDocsLink(doc.__db, this.name, 'new'));
        }

        const handleSave = () => {
            return dispatch(canSave
                ? saveRecord(doc.__fullPath)
                : infoDialog({
                    message: 'msg_no_save_privilege',
                    translate: true
                }));
        }

        const handleCopy = () => {
            if (!canAdd)
                return dispatch(infoDialog({
                    message: 'msg_no_create_privilege',
                    translate: true
                }));
            dispatch(copyRecord({ docPath: doc.__fullPath }));
            // navigate(getDocsLink(doc.__db, this.name, 'new'));
        }


        return [
            {
                icon: 'registry',
                tooltip: 'btn_back',
                onClick: () => navigate(getDocsLink(doc.__db, this.name)),
            },
            {
                icon: 'new',
                tooltip: 'btn_new',
                onClick: handleAdd,
                disabled: !canAdd,
                shortcut: {
                    key: 'n',
                    metaKey: true
                }
            },
            {
                icon: 'save',
                tooltip: 'btn_save',
                onClick: handleSave,
                disabled: !canSave,
                shortcut: {
                    key: 's',
                    metaKey: true
                }
            },
            {
                icon: 'delete',
                tooltip: 'btn_delete',
                onClick: handleDelete,
                disabled: !canDelete,
                shortcut: {
                    key: 'Backspace',
                    metaKey: true
                }
            },
            {
                icon: 'print',
                tooltip: 'btn_print',
                onClick: () => navigate(getDocsLink(doc.__db, this.name, doc.id) + '/print/' + Date.now()),
                disabled: !(isNotNew && this.isPrintable()),
                shortcut: {
                    key: 'p',
                    metaKey: true
                }
            },
            {
                icon: 'undo',
                tooltip: 'btn_undo',
                onClick: () => {
                    dispatch(undoRecord(doc.__fullPath))
                    dispatch(resetDocLinks(doc.__fullPath))
                },
            },
            {
                icon: 'copy',
                tooltip: 'btn_copy',
                onClick: handleCopy,
            },
            {
                icon: 'lock',
                tooltip: 'btn_lock',
                onClick: () => dispatch(executeAndReload({ docPath: doc.__fullPath, execute: 'lock' })),
                hidden: !this.isLockable() || this.isLocked(doc),
                shortcut: {
                    key: 'k',
                    metaKey: true,
                    shiftKey: true
                }
            },
            {
                icon: 'unlock',
                tooltip: 'btn_unlock',
                onClick: () => dispatch(executeAndReload({ docPath: doc.__fullPath, execute: 'unlock' })),
                // onClick: () => dispatch(unlockRecord({ docPath: doc.__fullPath })),
                hidden: !this.isLockable() || !this.isLocked(doc)
            },
        ]
    }
}
