import React from 'react'
import { DocGridColumn, RegistryDescriptor } from "../../../../app/types";
import { ApplicationUnit } from '../../../regs';

const EditForm = React.lazy(() => import('./PaymentTypeEdit'))

class PaymentTypesRD extends RegistryDescriptor {
    gridEndpoint = 'paymenttypes'
    docEndpoint = 'paymenttypes'
    selector = {
        endpoint: 'paymenttypes',
        idCol: 'id',
        captionCol: 'code',
        textCol: 'name',
        queryParams: {_orderBy: 'code'},
    }
    name: ApplicationUnit = 'paymenttypes'
    columns: DocGridColumn[] =  [
        {name: 'print_on_bank_requisits', width: 60, type: 'print_icon'},
        {name: 'code', width: 100},
        {name: 'name', width: 200},
        {name: 'iban', width: 200},
        {name: 'memo', width: 200},
    ]
    defaultDocument = {status: 0}
    getDetailForm = (docPath: string) => <EditForm docPath={docPath} />

    public on_update_iban = async (doc: any, value: any) => {
        return {...doc, bank_account: doc.iban};
    }
}

export const paymenttypes = new PaymentTypesRD()
